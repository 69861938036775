.HomePageGoogle {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // HomePageGoogleDuscount
    .HomePageGoogleDuscount {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ebf2ff;
        padding: 10px 5%;
        font-size: 20px;
        font-weight: 400;
        color: #0d402c;
        h3{
            font-size: 28px;
            margin: 0px 5px;
            font-weight: 500;
            color: #1b294b;
            margin: 0px 5px;
            cursor: pointer;
        }
        a {
            font-weight: 500;
            font-size: 28px;
            color: #1b294b;
            text-decoration: underline;
            margin: 0px 5px;
            cursor: pointer;
        }

        img {
            width: 30px;
            margin-right: 20px;
        }
    }
    // MainBanner
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: row-reverse;
        height: auto;
        // background-color: #eeeef5;
        padding: 60px 5% 30px 5%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .MainBannerLeft {
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            z-index: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .MainBannerLeft1 {
                display: flex;
                ruby-align: center;
                flex-direction: column;
                width: 70%;
                z-index: 1;
                h1 {
                    font-size: 70px;
                    font-weight: 600;
                    color: white;
                    line-height: 1;
                }
                h4 {
                    margin-top: 10px;
                    font-size: 40px;
                    font-weight: 600;
                    color: #2970eb;
                }
                .MainBannerLeft1Box {
                    display: none;
                    // mobile only
                }
                h2 {
                    font-size: 28px;
                    font-weight: 500;
                    margin-top: 10px;
                    color: white;
                    text-transform: capitalize;
                }
                h6 {
                    font-size: 22px;
                    font-weight: 400;
                    margin-top: 5px;
                    color: white;
                    text-transform: capitalize;
                    span {
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }
                .MainBannerLeft1Coupon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #1b294b8f;
                    backdrop-filter: blur(10px);
                    padding: 15px 55px;
                    border: 2px dashed white;
                    margin-top: 15px;
                    width: fit-content;
                    h2 {
                        text-align: center;
                        font-size: 26px;
                        margin-top: 0px;
                    }
                    h3 {
                        text-align: center;
                        font-size: 26px;
                        margin-top: 0px;
                        text-decoration: underline;
                    }
                    h4 {
                        text-align: center;
                        font-size: 20px;
                        margin-top: 5px;
                        color: white;
                        font-weight: 300;
                    }
                }

                h3 {
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: white;
                    margin-top: 15px;
                    img {
                        width: 30px;
                        margin-right: 5px;
                    }
                }
                .MainBannerLeft1BtnBonded {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .MainBannerLeftButtons {
                        display: flex;
                        flex-direction: column;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            justify-content: space-between;
                            color: white;
                            cursor: pointer;
                            border-radius: 3px;
                            cursor: pointer;
                            margin-top: 20px;
                            background-color: #2970eb;
                            width: 450px;
                            padding: 18px 20px;
                            font-size: 25px;
                            font-weight: 500;
                            img {
                                height: 25px;
                                width: 25px;
                                margin-left: 10px;
                                filter: invert(1);
                            }
                        }
                        a:hover {
                            text-decoration: underline;
                        }
                        a:last-child {
                            background-color: #1b294b;
                            color: white;
                            border: 2px solid white;
                        }
                    }
                    .MainBannerLeft1BtnBondedImg {
                        margin-left: 30px;
                        width: 140px;
                    }
                }
            }
            .MainBannerLeft2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                img {
                    width: 70%;
                    border-bottom: 5px solid white;
                }
                span {
                    display: none;
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            background-color: #abb5c89a;
            img {
                width: 100%;
                height: 100%;
                opacity: 1;
                object-fit: cover;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                left: 0px;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: rgb(255, 255, 255);
                background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 2%, #1b294b 100%);
                z-index: 1;
            }
            .MainBannerRightBubble2 {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 300px;
                background: #ffffff;
                background: linear-gradient(180deg, #ebf2ff 2%, #ffffff00 100%);
                z-index: 2;
            }
        }
    }
    // MainBannerTestimonoals
    .MainBannerTestimonoals {
        background-color: #1b294b;
        padding: 30px 5%;
        h6 {
            font-size: 23px;
            font-weight: 300;
            color: white;
            margin-top: 15px;
            margin-bottom: 8px;
            text-transform: capitalize;
            background-color: #2b3f6d8f;
            backdrop-filter: blur(10px);
            padding: 20px;
            border-radius: 5px;
            b {
                color: #2970eb;
                font-weight: 700;
                color: #ffffff;
                border-bottom: 1px solid white;
            }
        }
        h1 {
            font-size: 24px;
            font-weight: 400;
            color: white;
            margin-top: 20px;
        }
        .MainBannerLeftImages {
            display: flex;
            padding-top: 20px;
            width: 100%;
            overflow: auto;
            img {
                // width: 200px;
                height: 80px;
                margin-right: 40px;
                background-color: white;
                padding: 6px;
                border-radius: 10px;
            }
        }
        h2 {
            font-size: 24px;
            font-weight: 400;
            color: white;
            margin-top: 20px;
        }
        span {
            display: flex;
            margin-top: 10px;

            img {
                width: 20px;
                margin-right: 10px;
            }
        }
        h3 {
            font-size: 20px;
            font-weight: 300;
            color: white;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        a {
            cursor: pointer;
            font-size: 20px;
            font-weight: 300;
            color: white;
            text-decoration: underline;
        }
    }
    // BookAnAppointmentMainComponentEmergery
    .BookAnAppointmentMainComponentEmergery1 {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
        .BookAnAppointmentMainComponentEmergeryBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #2970eb;
            padding: 32px 5%;
            margin-top: 0px;
            width: 100%;
            border-bottom: 10px solid #a5dad1;
            img {
                display: none;
                width: 160px;
            }
            p {
                text-align: center;
                margin-right: 20px;
                color: #eceef6;
                font-size: 40px;
                font-weight: 600;
            }
            a {
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
                background-color: #1b294b;
                padding: 20px;
                border-radius: 3px;
            }
        }
    }
    // MainBanner2Top2
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .MainBanner2TopBoxMenuShadow1 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: #ebf2ff;
            background: linear-gradient(180deg, #1b294b 2%, #ebf2ff00 100%);
            z-index: 2;
        }
        .MainBanner2TopBoxMenu {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .MainBanner2TopBoxBox {
                display: flex;
                align-items: flex-start;
                justify-content: end;
                flex-direction: column;
                background-color: #393838;
                width: 25%;
                height: 520px;
                position: relative;
                position: relative;
                padding: 25px;
                .MainBanner2TopBoxImg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 0;
                    opacity: 1;
                }
                .MainBanner2TopBoxBox2 {
                    background-color: #1b294b8f;
                    opacity: 100%;
                    z-index: 2;
                    padding: 10px;
                    border-bottom: 3px solid #05179c;
                    min-height: 130px;
                    height: auto;
                    border-radius: 5px;
                    backdrop-filter: blur(10px);
                    h3 {
                        text-align: start;
                        font-size: 22px;
                        font-weight: 700;
                        color: white;
                        margin-bottom: 10px;
                        z-index: 2;
                    }
                    h2 {
                        text-align: start;
                        font-size: 18px;
                        font-weight: 300;
                        color: white;
                        z-index: 2;
                    }
                    .MainBanner2TopBoxBox2Btns {
                        display: none;
                        a {
                            margin-top: 10px;
                            padding: 10px 15px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #2970eb;
                            color: white;
                            cursor: pointer;
                            img {
                                width: 20px;
                                filter: invert(1);
                            }
                        }
                        a:last-child {
                            background-color: #1b294b;
                            border: 1px solid white;
                        }
                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .MainBanner2TopBoxBox:hover {
                .MainBanner2TopBoxBox2 {
                    .MainBanner2TopBoxBox2Btns {
                        display: block;
                    }
                }
            }
        }
        .MainBanner2TopBoxMenuShadow2 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 170px;
            background: #ebf2ff;
            background: linear-gradient(360deg, #1b294b 2%, #ebf2ff00 100%);
            z-index: 1;
        }
    }
    // HomePageGoogleReviews
    .HomePageGoogleReviews {
        padding: 50px 5% 0px 5%;
        background: #ebf2ff;
        h1 {
            font-size: 50px;
            font-weight: 700;
            color: #1b294b;
        }
        .HomePageGoogleReviewsList {
            display: flex;
            margin-top: 30px;
            overflow: auto;
            padding: 5px 5px;
            .HomePageGoogleReviewsBox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 40px;
                min-width: 300px;
                height: auto;
                // border: 1px solid #1b294b;
                padding: 15px 20px;
                border-radius: 5px;
                box-shadow:
                    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                background-color: white;
                h3 {
                    font-size: 18px;
                    font-weight: 300;
                    color: #1b294b;
                }
                .HomePageGoogleReviewsBoxMore {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    margin-top: 20px;
                    img {
                        width: 50px;
                        background-color: white;
                        border-radius: 50%;
                        padding: 5px;
                        margin-right: 10px;
                    }
                    span {
                        h2 {
                            font-size: 20px;
                            font-weight: 400;
                            color: #1b294b;
                        }
                        h4 {
                            display: flex;
                            img {
                                width: 20px;
                                padding: 2px;
                                background-color: unset;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    // MainBanner2
    .MainBanner2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 5% 50px 5%;
        background-color: #ebf2ff;
        .MainBanner2Left {
            // background-color: red;
            width: 70%;
            .MainBanner2TopTopRight {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                border-radius: 0px 8px 8px 0px;
                h4 {
                    font-size: 50px;
                    font-weight: 700;
                    color: #1b294b;
                }
                h5 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #242424;
                    margin-top: 10px;
                }
                .MainBanner2TopBox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 15px;
                    margin-top: 20px;
                    h2 {
                        text-align: center;
                        font-size: 25px;
                        font-weight: 600;
                        color: #1b294b;
                        // text-decoration: underline;
                        border-bottom: 2px solid #2970eb;
                    }
                    h3 {
                        text-align: start;
                        font-size: 18px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 5px;
                    }
                }
                .MainBanner2TopBox:last-child {
                    border-bottom: none;
                }
            }

            .MainBanner2TopTopBtn {
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-top: 40px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 23px;
                    font-weight: 500;
                    color: WHITE;
                    white-space: nowrap;
                    max-width: 400px;
                    width: 100%;
                    background-color: #2970eb;
                    border-radius: 3px;
                    cursor: pointer;
                    padding: 20px 40px;
                    padding-left: 20px;
                    margin-right: 0px;
                    margin-top: 20px;
                    img {
                        // background-color: white;
                        height: 20px;
                        width: 20px;
                        margin-left: 10px;
                        filter: invert(1);
                    }
                }
                a:hover {
                    text-decoration: underline;
                }
                a:last-child {
                    background-color: #1b294b;
                    color: white;
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
        .MainBanner2RightRgt {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            width: 30%;
            img {
                width: 80%;
            }
        }
    }
    // HomePageGoogleBefore
    .HomePageGoogleBefore {
        // display: none;
        padding: 50px 5% 50px 5%;
        background: #ebf2ff;
        background-color: #1b294b;

        h1 {
            font-size: 40px;
            font-weight: 600;
            color: #ebf2ff;
        }
        h2 {
            font-size: 20px;
            font-weight: 400;
            color: #ebf2ff;
            margin-top: 20px;
        }
        .HomePageGoogleBeforeImages {
            display: flex;
            align-items: center;
            margin-top: 40px;
            overflow: auto;
            padding-bottom: 10px;
            img {
                min-width: 300px;
                height: 200px;
                margin-right: 30px;
                border-radius: 5px;
                object-fit: cover;
                // transform: scale(1.1);
                transition: all 0.3s ease-in-out;
            }
            img:nth-child(1) {
                border-radius: 0px;
                height: 300px;
            }
            img:hover {
                border-radius: 0px;
                min-width: 400px;
                height: 300px;
            }
        }
    }

    // HomePageGoogleCoupon
    .HomePageGoogleCoupon {
        padding: 30px 5%;
        background-color: #ebf2ff;
        .HomePageGoogleCouponTop {
            background-color: white;
            padding: 30px;
            border-left: 3px solid #2970eb;
            display: flex;
            align-items: center;
            justify-content: center;
            .HomePageGoogleCouponText {
                padding-right: 100px;
                h1 {
                    font-size: 30px;
                    font-weight: 800;
                    color: #1b294b;
                }
                h2 {
                    font-size: 20px;
                    font-weight: 400;
                    color: #000000;
                    margin-top: 20px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #000000;
                    margin-top: 10px;
                    a {
                        text-decoration: underline;
                        font-weight: 400;
                        font-size: 18px;
                        color: #000000;
                    }
                }
            }
            .HomePageGoogleCouponMain {
                .coupon {
                    width: 400px;
                    height: 200px;
                    border-radius: 10px;
                    overflow: hidden;
                    margin: auto;
                    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
                    display: flex;
                    align-items: stretch;
                    position: relative;
                    text-transform: uppercase;
                }
                .coupon::before,
                .coupon::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 50%;
                    height: 100%;
                    z-index: -1;
                }

                .coupon::before {
                    left: 0;
                    background-image: radial-gradient(circle at 0 50%, transparent 25px, #a5dad1 26px);
                }

                .coupon::after {
                    right: 0;
                    background-image: radial-gradient(circle at 100% 50%, transparent 25px, #a5dad1 26px);
                }

                .coupon > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .left {
                    width: 20%;
                    border-right: 2px dashed rgba(0, 0, 0, 0.13);
                }
                .left div {
                    transform: rotate(-90deg);
                    white-space: nowrap;
                    font-weight: bold;
                }

                .center {
                    flex-grow: 1;
                    text-align: center;
                }

                .right {
                    width: 120px;
                    background-image: radial-gradient(circle at 100% 50%, transparent 25px, #fff 26px);
                }
                .right div {
                    // font-family: "Libre Barcode 128 Text", cursive;
                    font-weight: 600;
                    transform: rotate(-90deg);
                    font-size: 1rem;
                    color: #1b294b;
                }

                .center h2 {
                    background: #2970eb;
                    color: white;
                    padding: 0 10px;
                    font-size: 2.15rem;
                    white-space: nowrap;
                }

                .center h3 {
                    font-size: 2.15rem;
                    color: #1b294b;
                }
                .left h3 {
                    color: #1b294b;
                }
                .center small {
                    font-size: 1rem;
                    font-weight: 600;
                    letter-spacing: 2px;
                    color: #1b294b;
                }
            }
        }
    }
    // HomePageGoogleBefore
    .HomePageGoogleCompanies {
        padding: 50px 5% 50px 5%;
        background-color: #2970eb;
        margin-top: 0px;
        h1 {
            font-size: 40px;
            font-weight: 600;
            color: #ebf2ff;
        }
        h2 {
            font-size: 20px;
            font-weight: 400;
            color: #ebf2ff;
            margin-top: 20px;
        }
        .HomePageGoogleCompaniesImages {
            display: flex;
            align-items: center;
            // flex-wrap: wrap;
            margin-top: 40px;
            overflow: auto;
            // overflow: auto;
            padding-bottom: 10px;
            img {
                width: 150px;
                margin-right: 30px;
            }
        }
    }
    // HomePageGoogleServices
    .HomePageGoogleServices {
        background-color: white;
        position: relative;
        padding: 50px 5% 100px 5%;
        .MainBanner2TopBoxMenuShadow1 {
            padding: 50px 5%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: #ebf2ff;
            background: linear-gradient(180deg, #ebf2ff 2%, #ebf2ff00 100%);
            z-index: 1;
            h3 {
                font-size: 50px;
                font-weight: 700;
                color: #1b294b;
                margin-bottom: 30px;
                z-index: 4;
            }
        }
        .HomePageGoogleServicesMain {
            margin-top: 100px;
            .HomePageGoogleServicesBox {
                display: flex;
                height: 300px;
                // border-radius: 10px;
                background: #ebf2ff;
                margin-top: 50px;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                .HomePageGoogleServicesBoxLeft {
                    width: 50%;
                    img {
                        // border-radius: 10px 0px 0px 10px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .HomePageGoogleServicesBoxRight {
                    width: 50%;
                    padding: 40px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    h1 {
                        font-size: 30px;
                        font-weight: 600;
                        color: #1b294b;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 30px;
                    }
                }
            }
            .HomePageGoogleServicesBox:nth-child(2),
            .HomePageGoogleServicesBox:nth-child(4),
            .HomePageGoogleServicesBox:nth-child(6) {
                flex-direction: row-reverse;
                .HomePageGoogleServicesBoxLeft {
                    img {
                        // border-radius: 0px 10px 10px 0px;
                    }
                }
            }
        }
        .MainBanner2TopBoxMenuShadow2 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 170px;
            background: #ebf2ff;
            background: linear-gradient(360deg, #ebf2ff 2%, #ebf2ff00 100%);
            z-index: 1;
        }
    }

    // MainBanner6
    .MainBanner6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5% 0px 5%;
        width: 100%;
        margin-top: 0px;
        height: auto;
        background-color: #ebf2ff;

        .MainBanner6Left {
            width: 50%;
            padding-right: 50px;
            h2 {
                font-size: 50px;
                font-weight: 700;
                color: #1b294b;
            }
            h3 {
                font-size: 17px;
                font-weight: 400;
                color: #242424;
                margin-top: 10px;
                margin-bottom: 50px;
            }
            .MainBanner6LeftContact {
                color: white;
                font-size: 20px;
                font-weight: 500;
                border-radius: 3px;
                cursor: pointer;
                text-decoration: underline;
                background-color: #1b294b;
                padding: 20px;
            }
            .MainBanner4FaqMore {
                // background-color: #1b294b;
                // padding: 20px;
                margin: 50px 0px;
                border-radius: 5px;
                a {
                    color: #1b294b;
                    font-weight: 500;
                    text-decoration: underline;
                }
                .row-title-text,
                .styles_row-title-text__1MuhU {
                    font-weight: 600;
                }
                .faq-row,
                .styles_faq-row__2YF3c {
                    border-bottom: 2px solid #1b294b;
                }
            }
        }
        .MainBanner6Right {
            display: flex;
            align-items: center;
            width: 50%;
            height: 100%;
            img {
                width: 80%;
                margin-left: 100px;
            }
        }
    }
}
@media (max-width: 890px) {
    .HomePageGoogle {
        .HomePageGoogleDuscount {
            flex-direction: column;
            a{
                font-size: 20px;
            }
            h3 {
                font-size: 18px;
                font-weight: 400;
            }
        }
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 20px 5%;
            .MainBannerLeft {
                flex-direction: column;
                .MainBannerLeft1 {
                    width: 100%;

                    h1 {
                        text-align: center;
                        font-size: 30px;
                        font-weight: 600;
                    }
                    h4 {
                        text-align: center;
                        // color: white;
                        font-size: 26px;
                        margin-top: 10px;
                        color: #4187ff;
                    }
                    .MainBannerLeft1Box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 0px 5%;
                        gap: 10px;
                        margin-top: 10px;
                        img {
                            width: 33.3%;
                        }
                    }
                    h2 {
                        text-align: center;
                        font-size: 22px;
                        margin-top: 10px;
                        color: white;
                    }
                    h6 {
                        text-align: center;
                        font-size: 18px;
                        margin-top: 5px;
                        color: white;
                    }
                    .MainBannerLeft1Coupon {
                        margin-bottom: 10px;
                        width: 100%;
                        padding: 15px 0px;
                        h4 {
                            font-size: 16px;
                        }
                    }
                    h3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        width: 100%;
                    }
                    .MainBannerLeft1BtnBonded {
                        width: 100%;
                        .MainBannerLeftButtons {
                            width: 100%;
                            a:first-child{
                                font-size: 25px;

                            }
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                font-size: 18px;
                            }
                        }
                        .MainBannerLeft1BtnBondedImg {
                            display: none;
                        }
                    }

                }
                .MainBannerLeft2 {
                    display: none;
                }
            }
            .MainBannerRight {
                .MainBannerRightBubble1 {
                    width: 200%;
                }
            }
        }
        .MainBannerTestimonoals {
            padding-top: 5px;
            h6 {
                text-align: center;
                font-size: 18px;
                margin-bottom: 10px;
            }
            h1 {
                font-size: 18px;
            }
            .MainBannerLeftImages {
                img {
                    margin-right: 15px;
                }
            }
            h3 {
                font-size: 15px;
                margin-bottom: 10px;
            }
            a {
                font-size: 16px;
            }
        }
        .BookAnAppointmentMainComponentEmergery1 {
            width: 100%;
            .BookAnAppointmentMainComponentEmergeryBox {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    font-size: 30px;
                    text-align: center;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }
        .MainBanner2Top2 {
            // padding: 20px 5% 20px 5%;
            background: #ebf2ff;
            .MainBanner2TopBoxMenu {
                flex-direction: column;
                // gap: 20px;
                // padding-top: 30px;
                .MainBanner2TopBoxBox {
                    width: 100%;
                    // border-radius: 11px;
                    height: 390px;
                    padding: 15px;
                    margin-top: 6px;
                    img {
                        // border-radius: 10px;
                    }
                    h3 {
                        margin-bottom: 15px;
                    }
                    h2 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .HomePageGoogleCoupon {
            .HomePageGoogleCouponTop {
                padding: 0px;
                padding-bottom: 20px;
                flex-direction: column;
                .HomePageGoogleCouponText {
                    padding-right: 0px;
                    padding: 20px;
                    h1 {
                        font-size: 26px;
                        font-weight: 800;
                        color: #1b294b;
                    }
                    h2 {
                        font-size: 17px;
                        font-weight: 400;
                        color: #1b294b;
                        margin-top: 20px;
                    }
                }
                .HomePageGoogleCouponMain {
                    width: 100%;
                    padding: 20px;
                    .coupon {
                        filter: drop-shadow(0 3px 5px rgba(255, 255, 255, 0.5));
                        width: 100%;
                    }
                    .coupon::before,
                    .coupon::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        width: 50%;
                        height: 100%;
                        z-index: -1;
                    }
                    .right {
                        width: 60px;
                        padding-right: 20px;
                        // border: 1px solid black;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    }
                    .left {
                        width: 60px;
                        padding-left: 20px;
                    }
                }
            }
        }
        .HomePageGoogleReviews {
            padding: 30px 5% 0px 5%;
            h1 {
                font-size: 35px;
            }
            .HomePageGoogleReviewsList {
                margin-top: 10px;
                .HomePageGoogleReviewsBox {
                    margin-right: 20px;
                }
            }
        }
        .MainBanner2 {
            padding: 20px 5%;
            height: auto;
            flex-direction: column;
            .MainBanner2Left {
                width: 100%;
                .MainBanner2TopTopRight {
                    width: 100%;
                    h4 {
                        font-size: 35px;
                    }
                    .MainBanner2TopBox {
                        padding-left: 10px;
                        justify-content: start;
                        padding: 2px 0px;
                        h2 {
                            text-align: left;
                            font-size: 20px;
                        }
                        h3 {
                            text-align: left;
                            font-size: 16px;
                        }
                    }
                }
                .MainBanner2TopTopLeft {
                    width: 100%;
                    margin-left: 0px;
                    height: 200px;
                    padding: 0px;
                    margin-top: 30px;
                }
            }
            .MainBanner2RightRgt {
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 30px;
                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }
        }
        .HomePageGoogleBefore,
        .HomePageGoogleCompanies {
            padding: 30px 5% 50px 5%;

            h1 {
                font-size: 35px;
            }
            h2 {
                font-size: 17px;
            }
            .HomePageGoogleBeforeImages {
                img {
                    min-width: 250px;
                    height: 200px;
                    border-radius: 10px;
                }
                img:nth-child(1) {
                    border-radius: 10px;
                    height: 250px;
                }
                img:hover {
                    border-radius: 10px;
                    min-width: 280px;
                    height: 250px;
                }
            }
        }
        .HomePageGoogleServices {
            padding: 20px 5% 100px 5%;
            .MainBanner2TopBoxMenuShadow1 {
                padding: 30px 5%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 200px;
                background: #ebf2ff;
                background: linear-gradient(180deg, #ebf2ff 2%, #ebf2ff00 100%);
                z-index: 1;
                h3 {
                    font-size: 50px;
                    font-weight: 700;
                    color: #1b294b;
                    z-index: 4;
                }
            }
            .HomePageGoogleServicesMain {
                .HomePageGoogleServicesBox {
                    margin-top: 30px;
                    height: auto;
                    flex-direction: column;
                    border-radius: 0px;
                    .HomePageGoogleServicesBoxLeft {
                        width: 100%;
                        img {
                            border-radius: 10px 10px 0px 0px;
                            border-radius: 0px;

                            width: 100%;
                            height: 100%;
                            height: 200px;
                            object-fit: cover;
                        }
                    }
                    .HomePageGoogleServicesBoxRight {
                        padding: 20px 20px 30px 20px;
                        width: 100%;
                        border-radius: 0px;

                        h2 {
                            margin-top: 20px;
                            font-size: 17px;
                        }
                    }
                }
                .HomePageGoogleServicesBox:nth-child(2),
                .HomePageGoogleServicesBox:nth-child(4),
                .HomePageGoogleServicesBox:nth-child(6) {
                    flex-direction: column;
                    .HomePageGoogleServicesBoxLeft {
                        img {
                            border-radius: 10px 10px 0px 0px;
                            border-radius: 0px;
                        }
                    }
                }
            }
        }

        .MainBanner6 {
            height: auto;
            position: relative;
            flex-direction: column;
            padding-bottom: 70px;
            .MainBanner6Left {
                width: 100%;
                padding-right: 0px;
                z-index: 1;
                h2 {
                    font-size: 30px;
                }
                h3 {
                    padding-right: 0%;
                }
                .MainBanner4FaqMore {
                    .row-title-text,
                    .styles_row-title-text__1MuhU {
                        font-weight: 600;
                        font-size: 16px;
                    }
                    .faq-row,
                    .styles_faq-row__2YF3c {
                        border-bottom: 1px solid #2a1768;
                    }
                }
            }
            .MainBanner6Right {
                // position: absolute;
                // bottom: 0px;
                // right: 10px;
                // align-items: flex-end;
                width: 100%;
                img {
                    width: 100%;
                    margin-left: 0px;
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .HomePageGoogle {
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
            .MainBannerLeft {
                padding-left: 0px;
            }
            .MainBannerRight {
                // display: flex;
            }
        }
        .BookAnAppointmentMainComponentEmergery1 {
            .BookAnAppointmentMainComponentEmergeryBox {
                padding: 32px 13%;
            }
        }
        .HomePageGoogleReviews {
            padding: 50px 13% 0px 13%;
        }
        .MainBanner6,
        .MainBanner2,
        .HomePageGoogleCoupon,
        .HomePageGoogleBefore,
        .HomePageGoogleCompanies,
        .HomePageGoogleServices,
        .MainBannerTestimonoals {
            padding-right: 13%;
            padding-left: 13%;
            .MainBanner2TopBoxMenuShadow1 {
                padding-right: 13%;
                padding-left: 13%;
            }
        }
        .HomePageGoogleLocation {
            padding-right: 13%;
            padding-left: 13%;
        }
        .HomePageGoogleFooter {
            .HomePageGoogleFooterTop,
            .HomePageGoogleFooterBottom {
                padding-right: 13%;
                padding-left: 13%;
            }
        }
    }
}
@keyframes autoscroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
