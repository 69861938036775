.NotFound {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100vw;
  height: auto;
  padding-top: 80px;
  padding: 150px 0% 20px 0%;
  overflow-x: hidden;
  .NotFoundHeader {
    padding: 0px 5%;
    img {
      width: 40px;
      margin-bottom: 20px;
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
      color: #0f212e;
    }
    h2 {
      margin-top: 20px;
      color: #4f6473;
      font-size: 15px;
      font-weight: 300;
    }
  }
  .NotFoundMenu {
    padding: 0px 5%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    z-index: 3;
    a {
      padding: 0px;
      font-size: 15px;
      color: #0f212e;
      text-decoration: underline;
      white-space: unset;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .NotFoundNavigatorPath {
    padding: 0px 5% 30px 5%;
    display: flex;
    z-index: 3;
    margin-top: 30px;
    .NavigatorPathBox {
      display: flex;
      align-items: center;
      margin: 5px 5px 0px 0px;
      a {
        font-size: 14px;
        font-weight: 400;
        // white-space: nowrap;
        color: black;
        text-decoration: underline;
        cursor: pointer;
      }
      img {
        width: 20px;
        filter: invert(1);
        transform: rotate(-90deg);
      }
    }
  }
}

@media (max-width: 690px) {
  .NotFound {
    padding: 110px 0% 0px;
  }
}
@media screen and (min-width: 1400px) {
  .NotFound {
    padding: 150px 0% 100px 0%;

    .NotFoundHeader {
      padding: 0px 20%;
    }
    .NotFoundMenu {
      padding: 0px 20%;
    }
    .NotFoundNavigatorPath {
      padding: 0px 20% 30px 20%;
    }
  }
}
