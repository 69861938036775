.Header2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: auto;
  z-index: 5;
  // HeaderTopPhone
  .HeaderTopPhone {
    display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 30px 5%;
    // box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 3;
    background-color: #ebf2ff;
    background-color: white;
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .Header2Logo {
        // display: none;
        // background-color: red;
        img {
          margin-top: 6px;
          height: 30px;
          padding-bottom: 3px;
          // height: 45px;
          // margin-right: 8px;
        }
      }
      .Header2LogoName {
        flex-direction: column;
        justify-content: center;
        height: auto;
        display: none;

        a {
          font-size: 18px;
          white-space: nowrap;
          font-weight: 700;
          transform: scale(1, 1.2);
          color: #1b294b;
        }
        p {
          margin-top: -2px;
          color: #1b294b;
          font-weight: 300;
          font-size: 11px;
        }
      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 30px;
      img {
        max-width: 35px;
        max-height: 35px;
        min-width: 35px;
        min-height: 35px;
        // filter: invert(0);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 35px;
        max-height: 27px;
        min-width: 35px;
        min-height: 27px;

      }
      .Header2Circle {
        display: flex;
        padding: 8px 10px;
        border-radius: 3px;
        margin-right: 5px;
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .hdhs1 {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #2970ebbd;
            z-index: 3;
            z-index: 2;
            animation: glow 0.8s linear infinite;
          }
          .hdhs2 {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #2970eb;
            z-index: 3;
          }
        }
        h3 {
          font-size: 14px;
          font-weight: 500;
          margin-left: 10px;
          color: #1b294b;
        }
      }
    }
  }
  // HeaderBottom
  .HeaderBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1b294b;
    // box-shadow: 0px 10px 15px -3px #0000001a;
    width: 100%;
    height: 70px;
    padding: 20px 5%;

    .Header1Main {
      display: flex;
      .Header2Circle {
        display: flex;
        align-items: center;
        // background-color: #a5dad1;
        padding: 8px 15px;
        border-radius: 5px;
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .hdhs1 {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #2970ebbd;
            z-index: 3;
            z-index: 2;
            animation: glow 0.8s linear infinite;
          }
          .hdhs2 {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #2970eb;
            z-index: 3;
          }
        }
        h3 {
          font-size: 17px;
          font-weight: 500;
          margin-left: 10px;
          color: #ebf2ff;
        }
      }
      .Header1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          font-weight: 500;
          color: WHITE;
          cursor: pointer;
          background-color: #2970eb;
          border-radius: 0px;
          cursor: pointer;
          padding: 15px 20px;
          padding-left: 20px;
          margin-right: 0px;
          img {
            // background-color: white;
            height: 20px;
            width: 20px;
            margin-left: 10px;
            filter: invert(1);
          }
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .Header1:last-child {
        a {
          background-color: #a5dad1;
          color: black;
          img {
            filter: invert(0);
          }
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      .Header2Location {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img {
          width: 25px;
          margin-right: 10px;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          margin-left: 0px;
          color: #ebf2ff;
          letter-spacing: -1px;
        }
      }

    }
  }
  // HeaderTopComputer
  .HeaderTopComputer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ebf2ff;
    background-color: white;
    width: 100%;
    height: 80px;
    padding: 20px 5%;
    // box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 1;
    position: relative;
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .Header2Logo {
        display: flex;
        align-items: center;
        // display: none;
        img {
          width: 150px;
          // height: 100px;
          border-radius: 3px;
          margin-right: 10px;
          filter: invert(0);
        }
        p {
          color: #1b294b;
          font-weight: 600;
          font-size: 40px;
        }
      }
      .Header2LogoName {
        display: none;
        flex-direction: column;
        height: 50px;

        h1 {
          font-size: 32px;
          white-space: nowrap;
          font-weight: 700;
          transform: scale(1, 1.2);
          color: #1b294b;
        }
        h2 {
          color: #1b294b;
          font-weight: 300;
          font-size: 15px;
          margin-top: -3px;
        }
      }
    }
    .Header1 {
      display: flex;
      .Header1Box {
        padding: 20px 20px;
        .Header1BoxHedaer {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          h1,
          a {
            white-space: nowrap;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            color: #1b294b;
            margin-right: 10px;
            padding-bottom: 0px;
            border-bottom: 2px solid #1b294b;
          }
          img {
            width: 20px;
            margin-bottom: 0px;
            filter: invert(1);
          }
        }
      }
      .Header1Box:hover {
        .Header1BoxHedaer {
          h1 {
            // border-bottom: 2px solid #3572ef;
          }
        }
        .Header1BoxMenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

@keyframes warningApply {
  0% {
    bottom: (-100%);
    opacity: 50%;
  }
  100% {
    bottom: (0);
    opacity: (100%);
  }
}
@media (max-width: 900px) {
  .Header2 {
    // padding: 8px 0%;
    // max-height: 55px;
    .HeaderTopComputer {
      display: none;
    }
    .HeaderTopPhone {
      display: flex;
      height: 40px;
      padding: 25px 3%;
      .Header2 {
        h1 {
          font-size: 17px;
        }
      }
    }
    .HeaderBottom {
      height: 35px;
      padding: 25px 2%;
      justify-content: space-between;
      .Header2 {
        .Header2Circle {
          display: none;
        }
        .Header2Location {
          display: flex;
          width: auto;
          margin-right: 0px;
          img{
            width: 20px;
            margin-right: 5px;
          }

          h1 {
            font-size: clamp(1rem, 3vw, 3rem);
          }
        }
        .Header2Box {
          display: none;
          h2 {
            font-size: 14px;
          }
        }
      }
      .Header1Main {
        width: auto;
        gap: 10px;
        padding: 15px 0px;
        .Header2Circle{
          display: none;
        }
        .Header1 {
          margin-left: 0px;
          width: 100%;
          a {
            justify-content: center;
            padding: 10px 10px;
            // border-radius: 3px; 
            font-size: 13px;
            font-weight: 600;
            width: auto;
            img {
              // display: none;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .Header2 {
    // padding-right: 13%;
    // padding-left: 13%;
    .HeaderTopPhone,
    .HeaderBottom,
    .HeaderTopComputer {
      padding: 30px 13%;
    }
  }
}
@keyframes glow {
  0% {
    opacity: 30%;
    width: 10px;
    height: 10px;
  }
  100% {
    opacity: 50%;
    width: 20px;
    height: 20px;
  }
}
