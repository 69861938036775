.BookAnAppointmentMainComponent2 {
    height: auto;
    overflow: hidden;
    background-color: #1b294b;
    *select,
    *option,
    *textarea,
    input {
        -webkit-appearance: none;
        background-color: white;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
    }
    // HomePageGoogleDuscount
    .HomePageGoogleDuscount {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ebf2ff;
        padding: 10px 5%;
        font-size: 20px;
        font-weight: 400;
        color: #0d402c;
        h3{
            font-size: 28px;
            margin: 0px 5px;
            font-weight: 500;
            color: #1b294b;
            margin: 0px 5px;
            cursor: pointer;
        }
        a {
            font-weight: 500;
            font-size: 28px;
            color: #1b294b;
            text-decoration: underline;
            margin: 0px 5px;
            cursor: pointer;
        }

        img {
            width: 30px;
            margin-right: 20px;
        }
    }
    // BookAnAppointmentMainComponentTop
    .BookAnAppointmentMainComponentTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 60px 5% 0px 5%;

        .BookAnAppointmentMainComponentTopLeft {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            h1 {
                color: white;
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 10px;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
            h2 {
                color: white;
                font-size: 20px;
                font-weight: 300;
                margin-top: 2px;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
        }
    }
    // BookAnAppointmentMainComponentTopMain
    .BookAnAppointmentMainComponentTopMain {
        padding: 20px 5% 30px 5%;
        .BookAnAppointmentMainComponentMaps {
            display: flex;
            align-items: center;
            height: auto;
            width: 100%;
            margin-top: 20px;
            h4 {
                padding: 15px 20px 20px 20px;
                color: white;
                font-size: 20px;
                font-weight: 500;
                width: 160px;
                cursor: pointer;
                text-wrap: nowrap;
                // padding-top: 20px;
            }
        }
        .BookAnAppointmentMainPages {
            background-color: #a5dad1;
            padding: 30px 20px 20px 20px;
            border-radius: 10px 10px 0px 0px;

            .BookAnAppointmentMainComponentPage1,
            .BookAnAppointmentMainComponentPage2,
            .BookAnAppointmentMainComponentPage3 {
                border-radius: 0px;
                .BookAnAppointmentMainRightFullName,
                .BookAnAppointmentMainRightPhoneAndMaill,
                .BookAnAppointmentMainRightServices,
                .BookAnAppointmentMainRightDateAndTime {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .BookAnAppointmentMainRightBox {
                        width: 100%;
                        h4 {
                            color: #1b294b;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 20px;
                            span {
                                color: red;
                            }
                        }
                        h3 {
                            color: white;
                            font-size: 15px;
                            font-weight: 300;
                            margin-top: 10px;
                            background-color: #1b294b;
                            padding: 7px 10px;
                            border-radius: 3px;
                            span {
                                color: #1b294b;
                            }
                        }
                        input,
                        select {
                            padding: 0px 10px;
                            margin-top: 10px;
                            width: 100%;
                            min-height: 50px;
                            outline: none;
                            border: 1px solid #1b294b;
                            border-radius: 0px;
                        }
                        input:focus {
                            border: 1px solid #6f6f6f61;
                            outline: 1px solid #3572ef;
                        }
                    }
                }
                button {
                    background-color: #2970eb;
                    padding: 20px 20px;
                    border-radius: 0px;
                    color: #ebf2ff;
                    // margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 20px;
                    font-weight: 500;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                    border-radius: 3px;
                }
            }
            .BookAnAppointmentMainComponentPage2 {
                .BookAnAppointmentMainRightAddress1,
                .BookAnAppointmentMainRightAddress2,
                .BookAnAppointmentMainRightAddress3 {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .BookAnAppointmentMainRightBox {
                        width: 100%;
                        h4 {
                            color: #1b294b;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 20px;
                            span {
                                color: red;
                            }
                        }
                        input,
                        select {
                            padding: 0px 10px;
                            margin-top: 10px;
                            width: 100%;
                            min-height: 50px;
                            outline: none;
                            border: 1px solid #1b294b;
                            border-radius: 0px;
                        }
                        input:focus {
                            border: 1px solid #6f6f6f61;
                            outline: 1px solid #3572ef;
                        }
                    }
                }
                .BookAnAppointmentMainComponentButtons {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    button:first-child {
                        background-color: #0035534a;
                        color: black;
                    }
                    button {
                        background-color: #2970eb;
                        padding: 20px 20px;
                        color: white;
                        // margin-right: 30px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 18px;
                        outline: none;
                        border: none;
                        margin-top: 20px;
                    }
                }
            }
            .BookAnAppointmentMainComponentPage3 {
                .BookAnAppointmentMainRightNote {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .BookAnAppointmentMainRightBox {
                        width: 100%;
                        h4 {
                            color: #1b294b;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 20px;
                            span {
                                color: red;
                            }
                        }
                        textarea {
                            padding: 10px 10px;
                            margin-top: 10px;
                            margin-bottom: -10px;
                            width: 100%;
                            min-height: 120px;
                            outline: none;
                            border: 1px solid #1b294b;
                            resize: none;
                        }
                        input:focus {
                            border: 1px solid #6f6f6f61;
                            outline: 1px solid #3572ef;
                        }
                    }
                }
                .BookAnAppointmentMainComponentButtons {
                    display: flex;
                    gap: 20px;
                    .PREVIOUS {
                        background-color: #0035534a;
                        color: black;
                    }
                    form {
                        width: 160%;
                        margin-left: 20px;
                    }
                    button,
                    input {
                        background-color: #2970eb;
                        padding: 20px 20px;
                        color: white;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 18px;
                        outline: none;
                        border: none;
                        width: 100%;
                        margin-top: 20px;
                        i {
                            margin-right: 10px;
                        }
                    }
                    .buttonload button {
                        background-color: #2970eb;
                        color: white;
                    }
                }
            }
        }
        .BookAnAppointmentMainPagesCopun {
            background-color: #a5dad1;
            padding: 20px;
            padding-top: 0px;
            border-radius: 0px 0px 10px 10px;

            .BookAnAppointmentMainPagesCopunBox {
                display: flex;
                align-items: center;
                background-color: white;
                border: 2px solid #2970eb;
                padding: 20px;
                border-radius: 8px;
                .BookAnAppointmentMainPagesCopunLeft {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 23px;
                    min-height: 23px;
                    border-radius: 50%;
                    border: 1px solid #2970eb;
                    padding: 2px;
                    margin-right: 20px;
                    div {
                        border-radius: 50%;
                        background-color: #2970eb;
                        width: 15px;
                        height: 15px;
                    }
                }
                .BookAnAppointmentMainPagesCopunRight {
                    h1 {
                        font-size: 20px;
                        font-weight: 600;
                        color: #1b294b;
                    }
                    h2 {
                        font-size: 15px;
                        font-weight: 400;
                        color: #1b294b;
                    }
                }
            }
        }
    }
    // BookAnAppointmentMainComponentEmergery
    .BookAnAppointmentMainComponentEmergery {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
        background-color: #1b294b;
        margin-top: 0px;
        border-radius: 8px;
        padding: 0px 5% 40px 5%;
        .BookAnAppointmentMainComponentEmergeryBox {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            background-color: #243b70;
            padding: 12px 20px;
            border-radius: 8px;
            margin-top: 20px;
            text-align: center;
            

            a {
                color: white;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
            }
        }
        .BookAnAppointmentMainComponentRates {
            margin-top: 20px;
            border-radius: 10px;
            margin-top: 10px;
            padding: 20px;
            overflow: auto;
            width: 100%;
        background-color: #243b70;

            .BookAnAppointmentMainComponentRatesImages {
                display: flex;
                img {
                    width: auto;
                    height: 80px;
                    object-fit: cover;
                    margin-right: 20px;
                    // background-color: rgb(255, 255, 255);
                    padding: 7px;
                    border-radius: 5px;
                    background-color: white;
                    border-radius: 5px;
                }
            }
            h1 {
                color: white;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
            h2 {
                font-size: 20px;
                color: white;
                font-weight: 600;
                margin-top: 10px;
            }
            h3 {
                color: white;
                font-size: 17px;
                font-weight: 400;
                margin-top: 20px;
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .BookAnAppointmentMainComponent2 {
        .BookAnAppointmentMainComponentTop {
            padding: 50px 13% 0px 13%;
        }
        .BookAnAppointmentMainComponentTopMain {
            padding: 0px 13% 50px 13%;
        }
        .BookAnAppointmentMainComponentEmergery {
            padding: 0px 13% 40px 13%;
        }
    }
}
@media screen and (max-width: 800px) {
    .BookAnAppointmentMainComponent2 {
        .HomePageGoogleDuscount {
            flex-direction: column;
            a{
                font-size: 20px;
            }
            h3 {
                font-size: 18px;
                font-weight: 400;
            }
        }
        .BookAnAppointmentMainComponentTop {
            padding: 30px 5% 0px 5%;

            .BookAnAppointmentMainComponentTopLeft {
                h1 {
                    font-size: 24px;
                    font-weight: 600;
                    // transform: scale(0.9, 1);
                    // background-color: red;
                }
                h2 {
                    font-size: 14px;
                    font-weight: 300;
                    // margin-top: 10px;
                    // transform: scale(0.9, 1);
                    // background-color: red;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            .BookAnAppointmentMainComponentTopRigt {
                img {
                    width: 100px;
                    height: 100px;
                    // background-color: red;
                }
            }
        }
        .BookAnAppointmentMainComponentTopMain {
            padding: 0px 5% 20px 5%;
            .BookAnAppointmentMainComponentMaps {
                h4 {
                    padding: 10px 10px;
                    font-size: 18px;
                }
            }
            .BookAnAppointmentMainPages {
                padding: 20px 15px 20px 15px;

                .BookAnAppointmentMainComponentPage1 {
                    .BookAnAppointmentMainRightFullName,
                    .BookAnAppointmentMainRightPhoneAndMaill,
                    .BookAnAppointmentMainRightServices,
                    .BookAnAppointmentMainRightDateAndTime {
                        gap: 10px;
                        .BookAnAppointmentMainRightBox {
                            h4 {
                                font-size: 14px;
                                margin-top: 10px;
                            }
                            h3 {
                                font-size: 12px;
                            }
                        }
                    }
                    .BookAnAppointmentMainComponentButtons {
                        button {
                            padding: 20px 20px;
                            border-radius: 5px;
                            color: white;
                            margin-right: 30px;
                            cursor: pointer;
                            text-decoration: none;
                            font-size: 18px;
                            outline: none;
                            border: none;
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                }
                .BookAnAppointmentMainComponentPage2 {
                    .BookAnAppointmentMainRightAddress1,
                    .BookAnAppointmentMainRightAddress2,
                    .BookAnAppointmentMainRightAddress3 {
                        gap: 10px;
                        .BookAnAppointmentMainRightBox {
                            h4 {
                                font-size: 12px;
                                margin-top: 10px;
                            }
                        }
                    }
                    .BookAnAppointmentMainComponentButtons {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        gap: 20px;
                        button:first-child {
                        }
                        button {
                            align-self: flex-end;
                            place-self: end;
                            padding: 20px 20px;
                            // border-radius: 5px;
                            color: white;
                            // margin-right: 30px;
                            cursor: pointer;
                            text-decoration: none;
                            font-size: 18px;
                            outline: none;
                            border: none;
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                }
                .BookAnAppointmentMainComponentPage3 {
                    .BookAnAppointmentMainRightNote {
                        gap: 10px;
                        .BookAnAppointmentMainRightBox {
                            h4 {
                                font-size: 12px;
                                margin-top: 10px;
                            }
                        }
                    }
                    .BookAnAppointmentMainComponentButtons {
                        display: flex;
                        gap: 0px;
                        button:first-child {
                        }
                        button {
                            padding: 20px 20px;
                            // border-radius: 5px;
                            color: white;
                            // margin-right: 30px;
                            cursor: pointer;
                            text-decoration: none;
                            font-size: 18px;
                            outline: none;
                            border: none;
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
                }
            }
            .BookAnAppointmentMainPagesCopun {
                padding: 15px;
                padding-top: 0px;
                .BookAnAppointmentMainPagesCopunBox {
                    .BookAnAppointmentMainPagesCopunRight {
                        h2 {
                            font-size: 13px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            width: 100%;
            .BookAnAppointmentMainComponentEmergeryBox {
                display: flex;
                align-items: center;
                justify-content: start;
                // background-color: #1382d72b;
                padding: 12px 20px;
                border-radius: 8px;
                margin-top: 20px;

                a {
                    // color: #1b294b;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            .BookAnAppointmentMainComponentRates {
                margin-top: 20px;
                border-radius: 10px;
                margin-top: 10px;
                // padding: 20px;
                // background-color: #1382d72b;
                width: 100%;
                padding-top: 0px;

                .BookAnAppointmentMainComponentRatesImages {
                    display: flex;
                    width: 100%;
                    // flex-direction: column;
                    overflow: auto;
                    padding-bottom: 10px;
                    img {
                        width: auto;
                        height: 60px;
                        object-fit: cover;
                        margin-right: 20px;
                        margin-top: 20px;
                    }
                }
                h3 {
                    // color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 20px 0px;
                }
            }
        }
    }
}
