.BookAnAppointmentMainComponent {
    height: auto;
    overflow: hidden;
    padding: 0px 5%;
    *select,
    *option,
    *textarea,
    input {
        -webkit-appearance: none;
        background-color: white;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
    }
    .BookAnAppointmentMainComponentTop {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        h1 {
            color: #1c1e21;
            font-size: 30px;
            font-weight: 500;
            // transform: scale(0.9, 1);
            // background-color: red;
        }
        h2 {
            color: #1c1e21;
            font-size: 16px;
            font-weight: 300;
            margin-top: 10px;
            // transform: scale(0.9, 1);
            // background-color: red;
        }
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
    .BookAnAppointmentMainComponentMaps {
        display: flex;
        align-items: center;
        height: auto;
        width: 100%;
        margin-top: 20px;
        h4 {
            padding: 15px 20px 20px 20px;
            color: #5d5d5d;
            font-size: 20px;
            font-weight: 400;
            width: 160px;
            cursor: pointer;
            // padding-top: 20px;
        }
    }
    .BookAnAppointmentMainComponentPage1 {
        .BookAnAppointmentMainRightFullName,
        .BookAnAppointmentMainRightPhoneAndMaill,
        .BookAnAppointmentMainRightServices,
        .BookAnAppointmentMainRightDateAndTime {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .BookAnAppointmentMainRightBox {
                width: 100%;
                h4 {
                    color: #1c1e21;
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 20px;
                    span {
                        color: red;
                    }
                }
                input,
                select {
                    padding: 0px 10px;
                    margin-top: 10px;
                    width: 100%;
                    min-height: 50px;
                    outline: none;
                    border: 1px solid #6f6f6f61;
                    border-radius: 5px;
                }
                input:focus {
                    border: 1px solid #6f6f6f61;
                    outline: 1px solid #0157ff;
                }
            }
        }
        button {
            background-color: #0157ff;
            padding: 20px 20px;
            border-radius: 5px;
            color: white;
            margin-right: 30px;
            cursor: pointer;
            text-decoration: none;
            font-size: 18px;
            outline: none;
            border: none;
            width: 100%;
            margin-top: 20px;
        }
    }
    .BookAnAppointmentMainComponentPage2 {
        .BookAnAppointmentMainRightAddress1,
        .BookAnAppointmentMainRightAddress2,
        .BookAnAppointmentMainRightAddress3 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .BookAnAppointmentMainRightBox {
                width: 100%;
                h4 {
                    color: #1c1e21;
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 20px;
                    span {
                        color: red;
                    }
                }
                input,
                select {
                    padding: 0px 10px;
                    margin-top: 10px;
                    width: 100%;
                    min-height: 50px;
                    outline: none;
                    border: 1px solid #6f6f6f61;
                    border-radius: 5px;
                }
                input:focus {
                    border: 1px solid #6f6f6f61;
                    outline: 1px solid #0157ff;
                }
            }
        }
        .BookAnAppointmentMainComponentButtons {
            display: flex;
            gap: 20px;
            button:first-child {
                background-color: #267db0;
            }
            button {
                background-color: #0157ff;
                padding: 20px 20px;
                border-radius: 5px;
                color: white;
                // margin-right: 30px;
                cursor: pointer;
                text-decoration: none;
                font-size: 18px;
                outline: none;
                border: none;
                width: 100%;
                margin-top: 20px;
            }
        }
    }
    .BookAnAppointmentMainComponentPage3 {
        .BookAnAppointmentMainRightNote {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .BookAnAppointmentMainRightBox {
                width: 100%;
                h4 {
                    color: #1c1e21;
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 20px;
                    span {
                        color: red;
                    }
                }
                textarea {
                    padding: 10px 10px;
                    margin-top: 10px;
                    width: 100%;
                    min-height: 150px;
                    outline: none;
                    border: 1px solid #6f6f6f61;
                    border-radius: 5px;
                    resize: none;
                }
                input:focus {
                    border: 1px solid #6f6f6f61;
                    outline: 1px solid #0157ff;
                }
            }
        }
        .BookAnAppointmentMainComponentButtons {
            display: flex;
            gap: 20px;
            button:first-child {
                background-color: #267db0;
            }
            button:last-child {
                margin-left: 20px;
            }
            form {
                width: 160%;
                margin-left: 20px;
            }
            button,
            input {
                background-color: #0157ff;
                padding: 20px 20px;
                border-radius: 5px;
                color: white;
                cursor: pointer;
                text-decoration: none;
                font-size: 18px;
                outline: none;
                border: none;
                width: 100%;
                margin-top: 20px;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
    .BookAnAppointmentMainComponentThanks {
        padding: 40px 0px;
        h3 {
            text-align: center;
            font-weight: 400;
        }
        h4 {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 10px;
            color: #5d5d5d;
            font-weight: 400;
            h5 {
                cursor: pointer;
                color: #0157ff;
                border: 1px solid #919191;
                padding: 10px 20px;
                border-radius: 2px;
                margin-top: 15px;
            }
        }
    }
    .BookAnAppointmentMainComponentContent {
        margin-top: 50px;
        ul,
        ol {
            li {
                font-size: 17px;
                font-weight: 300;
                margin-top: 10px;
            }
        }
        h4 {
            margin-top: 15px;
        }

        p {
            color: #1c1e21;
            font-size: 18px;
            font-weight: 300;
            margin-top: 20px;
        }
        p strong {
            color: #1c1e21;
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
        }
    }
}
@media screen and (min-width: 1400px) {
    .BookAnAppointmentMainComponent {
        padding: 50px 20%;
    }
}
// @media screen and (max-width: 800px) {
//     .BookAnAppointmentMainComponent {
//         top: 0px;
//         padding: 50px 30px;
//         max-height: calc(100%);
//         min-height: calc(100%);
//         .BookAnAppointmentMainComponentTop {
//             h1 {
//                 color: #1c1e21;
//                 font-size: 25px;
//             }
//             img {
//                 width: 25px;
//                 height: 25px;
//                 cursor: pointer;
//             }
//         }
//     }
// }
@media screen and (max-width: 800px) {
    .BookAnAppointmentMainComponent {
        // top: 0px;
        padding: 0px;
        border: 20px solid white;
        max-height: calc(100%);
        min-height: calc(100%);
        .BookAnAppointmentMainComponentTop {
            h1 {
                color: #1c1e21;
                font-size: 22px;
                font-weight: 500;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
            h2 {
                color: #1c1e21;
                font-size: 14px;
                font-weight: 300;
                margin-top: 10px;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
            img {
                width: 20px;
                height: 20px;
            }
        }
        .BookAnAppointmentMainComponentMaps {
            h4 {
                padding: 10px 10px;
                font-size: 12px;
            }
        }
        .BookAnAppointmentMainComponentPage1 {
            .BookAnAppointmentMainRightFullName,
            .BookAnAppointmentMainRightPhoneAndMaill,
            .BookAnAppointmentMainRightServices,
            .BookAnAppointmentMainRightDateAndTime {
                gap: 10px;
                .BookAnAppointmentMainRightBox {
                    h4 {
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
            .BookAnAppointmentMainComponentButtons {
                background-color: rgb(255, 255, 255);

                button {
                    background-color: #0157ff;
                    padding: 20px 20px;
                    border-radius: 5px;
                    color: white;
                    margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
        .BookAnAppointmentMainComponentPage2 {
            .BookAnAppointmentMainRightAddress1,
            .BookAnAppointmentMainRightAddress2,
            .BookAnAppointmentMainRightAddress3 {
                gap: 10px;
                .BookAnAppointmentMainRightBox {
                    h4 {
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
            .BookAnAppointmentMainComponentButtons {
                display: flex;
                gap: 20px;
                background-color: rgb(255, 255, 255);
                button:first-child {
                    background-color: #267db0;
                }
                button {
                    background-color: #0157ff;
                    padding: 20px 20px;
                    border-radius: 5px;
                    color: white;
                    // margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
        .BookAnAppointmentMainComponentPage3 {
            .BookAnAppointmentMainRightNote {
                gap: 10px;
                .BookAnAppointmentMainRightBox {
                    h4 {
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
            .BookAnAppointmentMainComponentButtons {
                display: flex;
                gap: 0px;
                background-color: rgb(255, 255, 255);
                button:first-child {
                    background-color: #267db0;
                }
                button {
                    background-color: #0157ff;
                    padding: 20px 20px;
                    border-radius: 5px;
                    color: white;
                    // margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}
