.Footer2 {
  background-color: #ebf2ff;
  // border-top: 10px solid #1b294b;
  // HomePageGoogleLocation

  // HomePageGoogleLocation
  .HomePageGoogleLocation {
    display: flex;
    padding: 20px 5% 20px 5%;
    background-color: #ebf2ff;
    border-top: 2px solid #1b294b;
    align-items: center;
    height: 500px;
    .HomePageGoogleLocationLeft {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;
      height: 100%;
      padding-right: 20px;
      h1 {
        font-size: 30px;
        font-weight: 700;
        color: #1b294b;
        text-align: start;
      }
      h2 {
        text-align: start;
        font-size: 28px;
        font-weight: 400;
        color: #1b294b;
        margin-top: 10px;
        margin-bottom: 8px;
        text-transform: capitalize;
        b {
          color: #2970eb;
        }
        span {
          font-weight: 600;
        }
      }
      h3 {
        text-align: start;
        font-size: 23px;
        font-weight: 400;
        color: #09031f;
        margin-top: 0px;
        margin-bottom: 8px;
        text-transform: capitalize;
        b {
          color: #da291c;
        }
      }
      .HomePageGoogleLocationLeftBox {
        margin-top: 10px;
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: #1b294b;
          text-align: start;
        }
        h5 {
          font-size: 20px;
          font-weight: 500;
          color: #09031f;
          text-align: start;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        a {
          font-size: 20px;
          font-weight: 400;
          color: #09031f;
          text-align: start;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .HomePageGoogleLocationRight {
      position: relative;
      width: 50%;
      height: 100%;
      padding: 10px;
      margin-top: -20px;
      .HomePageGoogleLocationRightBorder {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #3b3b7b00;
        border: 2px solid #ebf2ff;
        border-top: 68px solid #ebf2ff;
        z-index: 1;
      }
      iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
    }
  }
  .HomePageGoogleFooterTop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: -40px;
    background-color: white;
    padding: 50px 5% 50px 5%;

    .HomePageGoogleFooterTopTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      // justify-content: space-between;
      h1 {
        font-size: 30px;
        font-weight: 600;
        color: #2970eb;
        text-align: center;
        font-style: oblique 50deg;
        margin: 0px 20px;
      }
      img {
        width: 40px;
      }
    }
  }
  .HomePageGoogleFooterBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1b294b;
    margin-top: 50px;
    padding: 50px 5% 90px 5%;

    .HomePageGoogleFooterBottomTop {
      width: 50%;
      padding-right: 100px;
      .Header2LogoName {
        h1 {
          font-size: 28px;
          white-space: nowrap;
          font-weight: 700;
          transform: scale(1, 1.2);
          color: white;
          margin-top: 20px;
        }
        h2 {
          color: white;
          font-weight: 300;
          font-size: 15px;
          margin-top: -3px;
        }
      }
      img {
        // display: none;
        width: 100px;
        // height: 100px;
      }
      p {
        margin-top: 10px;
        color: white;
      }
      .SiteMapDiv {
        h1 {
          margin-top: 20px;
          color: white;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        a {
          font-size: 15px;
          color: white;
          text-decoration: underline;
          margin-right: 10px;
        }
      }
    }
    .HomePageGoogleFooterMiddle {
      width: 20%;
      .HomePageGoogleLocationLeftBox {
        margin-top: 20px;
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: #2970eb;
          text-align: start;
        }
        h5 {
          font-size: 18px;
          font-weight: 500;
          color: white;
          text-align: start;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        a {
          font-size: 20px;
          font-weight: 400;
          color: white;
          text-align: start;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .HomePageGoogleFooterBottomBottom {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        img {
          width: 30px;
          margin-right: 15px;
          // background-color: white;
          border-radius: 5%;
          padding: 3px;
          outline: 2px solid white;
        }
        a {
          font-size: 15px;
          font-weight: 500;
          // text-decoration: underline;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .Footer2 {
    .HomePageGoogleLocation {
      flex-direction: column;
      height: auto;
      padding-top: 40px;
      .HomePageGoogleLocationLeft {
        padding-right: 0px;
        width: 100%;
        h1{
          font-size: 22px;
        }
        h2 {
          font-size: 20px;
        }
        h3{
          font-size: 18px;
          font-weight: 300;
        }
        .HomePageGoogleLocationLeftBox {
          background-color: white;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          z-index: 2;
          h5 {
            font-size: 18px;
          }
        }
      }
      .HomePageGoogleLocationRight {
        width: 100%;
        min-height: 400px;
        margin-top: -50px;
        z-index: 1;
        .HomePageGoogleLocationRightBorder{
          border-top: 68px solid #ebf2ff;

        }
        iframe{

        }
      }
    }
    .HomePageGoogleFooterTop {
      .HomePageGoogleFooterTopTitle {
        h1 {
          font-size: 28px;
          margin: 0px 6px;
        }
      }
    }
    .HomePageGoogleFooterBottom {
      flex-direction: column-reverse;
      .HomePageGoogleFooterBottomTop {
        padding-right: 0px;
        margin-top: 50px;
        width: 100%;
        p {
          font-size: 12px;
        }
      }
      .HomePageGoogleFooterMiddle {
        width: 100%;
        margin-top: 50px;
      }
      .HomePageGoogleFooterBottomBottom {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        span {
          img {
            width: 20px;
            padding: 4px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .Footer2 {
    .HomePageGoogleLocation {
      padding: 50px 13%;
    }
    .HomePageGoogleFooterTop {
      padding: 50px 13%;
    }
    .HomePageGoogleFooterBottom {
      padding: 50px 13% 90px 13%;
    }
  }
}
